import "./App.css";
import { useEffect, useState } from "react";
import PhoneInput from "./components/PhoneInput";
import axios from "axios";
import Image from "./components/Image";
import ReactFlagsSelect from "react-flags-select";
import countries from "./constants/countries";
import { parsePhoneNumberFromString } from "libphonenumber-js";

function App() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [nationality, setNationality] = useState("");
  const [message, setMessage] = useState("");
  const [phoneWithCode, setPhoneWithCode] = useState("");
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [triedSubmitted, setTriedSubmitted] = useState(false);
  const [minimized, setMinimized] = useState(true);
  const handleFullName = (event) => {
    const fullName = event.target.value;
    setFullName(fullName);
    if (triedSubmitted && !fullName) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fullName: "Full Name is required",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, fullName: "" }));
    }
  };

  const handleEmail = (event) => {
    const email = event.target.value;
    setEmail(email);
    if (triedSubmitted && !validateEmail(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handleMessage = (event) => {
    const message = event.target.value;
    setMessage(message);
    if (triedSubmitted && !message) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        message: "Message is required",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, message: "" }));
    }
  };

  const handleNationality = (countryName) => {
    setNationality(countryName);
    if (triedSubmitted && !countryName) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationality: "Nationality is required",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, nationality: "" }));
    }
  };

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setNationality(data.country_code);
      })
      .catch((error) => {
        setNationality("US");
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  const handlePhoneChange = (phoneWithCode) => {
    setPhoneWithCode(phoneWithCode);
    if (triedSubmitted) {
      if (!phoneWithCode) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneWithCode: "Phone number is required",
        }));
      } else if (!isPhoneValid(phoneWithCode)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneWithCode: "Phone number is invalid",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneWithCode: "",
        }));
      }
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, phoneWithCode: "" }));
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (attachedFiles.length + files.length > 5) {
      alert("You can only upload up to 5 files.");
      return;
    }
    setAttachedFiles((prevFiles) => [...prevFiles, ...files]);
    setFileNames((prevNames) => [
      ...prevNames,
      ...files.map((file) => file.name),
    ]);
  };

  const handleRemoveFile = (index) => {
    setAttachedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setFileNames((prevNames) => prevNames.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const fileErrors = validateFiles(attachedFiles);
    if (fileErrors) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        files: fileErrors,
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, files: "" }));
    }
  }, [attachedFiles]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const isPhoneValid = (phoneWithCode) => {
    const phoneNumberObject = parsePhoneNumberFromString(phoneWithCode);
    if (phoneNumberObject) {
      return phoneNumberObject.isValid();
    } else {
      return false;
    }
  };

  const validateFiles = (files) => {
    // Validate file size (2MB)
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    // Validate file type (image, pdf, doc)
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (file.size > maxSize) {
        return `${file.name} size exceeds 2MB.`;
      }

      if (!allowedTypes.includes(file.type)) {
        return `${file.name} is an invalid file type. Please upload an image, PDF, or DOC file.`;
      }
    }

    return false;
  };

  const validateForm = () => {
    const newErrors = {};
    if (!fullName) newErrors.fullName = "Full Name is required";
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(email)) {
      newErrors.email = "Invalid email address";
    }
    if (!nationality) newErrors.nationality = "Nationality is required";
    if (!message) newErrors.message = "Message is required";

    if (!phoneWithCode) {
      newErrors.phoneWithCode = "Phone number is required";
    } else if (!isPhoneValid(phoneWithCode)) {
      newErrors.phoneWithCode = "Phone number is invalid";
    }

    const fileErrors = validateFiles(attachedFiles);
    if (fileErrors) {
      newErrors.files = fileErrors;
    }

    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setTriedSubmitted(true);
    setLoading(true);
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("email", email);
    formData.append("nationality", countries[nationality].name);
    formData.append("message", message);
    formData.append(
      "phone",
      parsePhoneNumberFromString(phoneWithCode).formatInternational()
    );

    for (let i = 0; i < attachedFiles.length; i++) {
      formData.append("files[]", attachedFiles[i]);
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/chat-widget`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Clear the form fields upon successful submission
      setFullName("");
      setEmail("");
      setNationality("");
      setMessage("");
      setPhoneWithCode("");
      setAttachedFiles([]);
      setFileNames([]);
      setErrors({});
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting the form:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleMinimize = () => {
    setMinimized(true);
  };

  const handleExpand = () => {
    setMinimized(false);
  };

  return (
    <>
      {!minimized && (
        <div className="chat-text-center chat-bg-cover chat-bg-center chat-bg-repeat chat-fixed chat-bottom-[0] chat-right-0 md:chat-right-5 chat-h-full md:chat-h-[99vh] chat-overflow-y-auto chat-overflow-x-hidden chat-rounded-0 md:chat-rounded-md chat-bg-[linear-gradient(180deg,_#002445_14%,_#FFFFFF_94.85%)] custom-scrollbar">
          <div
            className={
              "chat-container chat-mx-auto chat-w-full chat-h-full md:chat-w-[390px]"
            }
          >
            <header className="App-header chat-text-white chat-px-4">
              <div className="chat-flex chat-items-center chat-justify-between">
                {!minimized && (
                  <>
                    <div className="chat-flex chat-items-center">
                      <Image
                        src={process.env.PUBLIC_URL + "/svg/map.svg"}
                        className="chat-w-[14px] App-logo chat-mr-2"
                        alt="map"
                      />
                      <p
                        className={
                          "chat-roboto-font chat-text-left chat-text-[13px]"
                        }
                      >
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:chat-underline"
                          href="https://www.google.com/maps/place/1629+K+St+NW+%23300,+Washington,+DC+20006,+%E1%8B%A9%E1%8A%93%E1%8B%AD%E1%89%B5%E1%8B%B5+%E1%88%B5%E1%89%B4%E1%89%B5%E1%88%B5/@38.9028661,-77.0384972,19z/data=!4m6!3m5!1s0x89b7b7bec1bdc7c9:0x111b4f4a48b1e3ad!8m2!3d38.9028651!4d-77.0378535!16s%2Fg%2F11nyp5z1g9?entry=tts&g_ep=EgoyMDI0MDcyMy4wKgBIAVAD"
                        >
                          1629 K St., Suite 300, Washington D.C. 20006
                        </a>
                      </p>
                    </div>
                    <button
                      className="focus:chat-outline-none"
                      onClick={handleMinimize}
                    >
                      <Image
                        src={process.env.PUBLIC_URL + "/svg/minimize.svg"}
                        className="App-logo chat-w-[20px] chat-cursor-pointer"
                        alt={minimized ? "expand" : "minimize"}
                      />
                    </button>
                  </>
                )}
              </div>
              {!minimized && (
                <>
                  <div className="chat-flex chat-items-center chat-justify-between">
                    <Image
                      src={process.env.PUBLIC_URL + "/svg/logo.svg"}
                      className="chat-w-[155px] App-logo"
                      alt="logo"
                    />
                    <Image
                      src={process.env.PUBLIC_URL + "/svg/people.svg"}
                      className="App-logo chat-w-[86px]"
                      alt="people"
                    />
                  </div>
                  {submitted && (
                    <div className="chat-mt-4 chat-mb-4">
                      <p className={"chat-text-left chat-text-[16px]"}>
                        Thank you for reaching out to GovAssist. A member of our
                        team will get back to you within the next 24 hours to
                        assist you further.
                      </p>
                    </div>
                  )}
                  {!submitted && (
                    <div>
                      <h2 className="chat-archivo-font chat-text-left chat-text-[26px] chat-font-medium chat-text-white">
                        Hi, we are here to help!
                      </h2>
                      <p
                        className={
                          "chat-roboto-font chat-text-left chat-text-md chat-text-gray-200"
                        }
                      >
                        Describe what you need help with and we'll get back to
                        you within 24 hours!
                      </p>
                    </div>
                  )}
                  <p
                    className={
                      "chat-roboto-font chat-text-left chat-text-[13px] chat-mt-4"
                    }
                  >
                    For immediate assistance in emergencies, please call us at:
                  </p>
                  <div className={"chat-flex"}>
                    <div className="chat-mt-[3px]">
                      <Image
                        src={process.env.PUBLIC_URL + "/svg/phone.svg"}
                        className="chat-mt-[5px] chat-mr-2 chat-w-[13px]"
                        alt="phone"
                      />
                    </div>
                    <div className="chat-mt-1">
                      <p className="chat-text-[16px]"> +1 (208) 417-7011</p>
                    </div>
                    <div>
                      <a href="tel:+12084177011">
                        <button className="chat-roboto-font chat-mt-[4px] chat-rounded-[20px] chat-ml-4 chat-w-[80px] chat-text-[16px] chat-bg-[#91D6B5] chat-text-[#0F9656] chat-px-2">
                          Call Us
                        </button>
                      </a>
                    </div>
                  </div>
                  {!submitted && (
                    <form
                      onSubmit={handleSubmit}
                      className="chat-mx-auto chat-py-6 chat-rounded"
                    >
                      <div className="chat-mb-4">
                        <input
                          onChange={handleFullName}
                          value={fullName}
                          className={`chat-rounded-[8px] chat-h-[52px] chat-shadow chat-appearance-none chat-border chat-w-full chat-py-2 chat-px-3 chat-text-gray-700 chat-leading-tight focus:chat-outline-none focus:chat-shadow-outline ${
                            errors.fullName ? "chat-border-red-500" : ""
                          }`}
                          id="full-name"
                          type="text"
                          placeholder="Full Name"
                        />
                        {errors.fullName && (
                          <p className="error-message">{errors.fullName}</p>
                        )}
                      </div>
                      <div className="chat-mb-4">
                        <input
                          onChange={handleEmail}
                          value={email}
                          className={`chat-rounded-[8px] chat-h-[52px] chat-shadow chat-appearance-none chat-border chat-w-full chat-py-2 chat-px-3 chat-text-gray-700 chat-leading-tight focus:chat-outline-none focus:chat-shadow-outline ${
                            errors.email ? "chat-border-red-500" : ""
                          }`}
                          id="email"
                          type="email"
                          placeholder="E-mail"
                        />
                        {errors.email && (
                          <p className="error-message">{errors.email}</p>
                        )}
                      </div>
                      <div className="chat-mb-4">
                        <ReactFlagsSelect
                          className={`chat-rounded-[8px] chat-w-full chat-flex chat-items-center chat-pt-[5px] chat-h-[52px] chat-appearance-none chat-leading-tight chat-text-black chat-bg-white chat-cursor-pointer ${
                            errors.nationality
                              ? "chat-border chat-border-red-500"
                              : ""
                          }`}
                          selectButtonClassName="!chat-border-none"
                          selectedSize={16}
                          optionsSize={16}
                          placeholder="Nationality"
                          selected={nationality}
                          onSelect={(code) => handleNationality(code)}
                          searchable
                          countries={Object.keys(countries)}
                        />
                        {errors.nationality && (
                          <p className="error-message !chat-text-red-400">
                            {errors.nationality}
                          </p>
                        )}
                      </div>

                      <div className="chat-mb-4">
                        <PhoneInput
                          onChange={handlePhoneChange}
                          nationality={nationality}
                        />
                        {errors.phoneWithCode && (
                          <p className="error-message">
                            {errors.phoneWithCode}
                          </p>
                        )}
                      </div>

                      <div className="chat-mb-4">
                        <textarea
                          onChange={handleMessage}
                          value={message}
                          className={`chat-rounded-[8px] chat-shadow chat-appearance-none chat-border chat-w-full chat-py-2 chat-px-3 chat-text-gray-700 chat-leading-tight focus:chat-outline-none focus:chat-shadow-outline ${
                            errors.message ? "chat-border-red-500" : ""
                          }`}
                          id="message"
                          rows="5"
                          placeholder="Please provide detailed information including desired product, country of residence, travel dates, and a description of your issue."
                        ></textarea>
                        {errors.message && (
                          <p className="error-message !chat-text-red-400">
                            {errors.message}
                          </p>
                        )}
                      </div>
                      <div className="chat-mb-4 chat-flex chat-justify-center chat-items-center">
                        <label className="chat-roboto-font chat-flex chat-items-center chat-rounded-[20px] chat-w-[128px] chat-bg-white chat-px-2.5 chat-py-1.5 chat-text-sm chat-text-gray-900 chat-shadow-sm chat-ring-1 chat-ring-inset chat-ring-gray-300 hover:chat-bg-gray-50 chat-cursor-pointer">
                          <Image
                            src={process.env.PUBLIC_URL + "/svg/paperclip.svg"}
                            className="chat-w-[14px] chat-mr-2"
                            alt="paperclip"
                          />
                          Attach Files
                          <input
                            type="file"
                            className="chat-hidden"
                            accept=".png,.jpg,.jpeg,.pdf"
                            multiple
                            onChange={handleFileChange}
                          />
                        </label>
                      </div>
                      {errors.files && (
                        <p className="error-message !chat-text-red-400 chat-p-1 chat-border chat-border-red-500 chat-rounded">
                          {errors.files}
                        </p>
                      )}
                      <div className="chat-mb-4">
                        {fileNames.map((fileName, index) => (
                          <div
                            key={index}
                            className="chat-text-white chat-flex chat-items-center"
                          >
                            <span className="chat-text-black chat-text-left">
                              {fileName}
                            </span>
                            <button
                              type="button"
                              onClick={() => handleRemoveFile(index)}
                              className="chat-ml-2 chat-text-red-600 chat-underline chat-cursor-pointer hover:chat-text-red-700 focus:chat-outline-none"
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                      </div>
                      <div className="chat-mt-6 chat-flex chat-items-center chat-justify-between">
                        <button
                          className="chat-archivo-font chat-rounded-[8px] chat-w-full chat-bg-[#0067FF] hover:chat-bg-blue-700 chat-text-white chat-font-bold chat-py-2 chat-px-4 focus:chat-outline-none focus:chat-shadow-outline"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? "Sending..." : "Send"}
                        </button>
                      </div>
                    </form>
                  )}
                </>
              )}
            </header>
          </div>
        </div>
      )}
      {minimized && (
        <div className="">
          <button
            className="chat-fixed chat-bottom-0 chat-right-0 focus:chat-outline-none"
            onClick={handleExpand}
            id="chat-button"
          >
            <Image
              src={process.env.PUBLIC_URL + "/svg/chat.svg"}
              className="chat-w-[73px] chat-h-[73px] chat-cursor-pointer"
              alt={minimized ? "expand" : "minimize"}
            />
          </button>
        </div>
      )}
    </>
  );
}

export default App;
